import React from 'react';
import {ChangeTeamButton} from './ChangeTeamButton.js';
import {ShowTeamsButton} from './ShowTeamsButton.js';

export const TeamInfo = (props) => {
  const {team, setShowTeamPicker, setShowTeam, name} = props;

  const teamIndicator = () => {
    if (!team) {
      return null;
    }
    var classes=['bold'];
    classes.push(team);
    return (
      <span className={classes.join(' ')}>{name}, you're {team}</span>
    );
  }

  return (
    <div className="team-info">
      {teamIndicator()}
      <ChangeTeamButton team={team} setShowTeamPicker={setShowTeamPicker} />
      <ShowTeamsButton setShowTeam={setShowTeam} />
    </div>
  );
}
