import React from 'react';

export const ShowTeamsButton = (props) => {
  const {setShowTeam} = props;

  var classes = ["see-teams-button", "white", "underline"];

  const onButtonClicked = () => {
    setShowTeam(true);
  }

  return (
    <React.Fragment>
      <button className={classes.join(' ')} onClick={onButtonClicked}>See Teams</button>
    </React.Fragment>
  );
}
