import React from 'react';

export const NameInput = (props) => {
  const {name, inputtedName, setInputtedName} = props;

  if (name != '') {
    return null;
  }

  const placeHolder = '(required*)';

  return (
    <React.Fragment>
      <h2>Your Name</h2>
      <p>So that your teammate can find you. Alphanumeric only please!</p>
      <input
        value={inputtedName}
        type="text"
        placeholder={placeHolder}
        onChange={e => setInputtedName(e.target.value)}
        onFocus={(e) => e.target.placeholder=""}
        onBlur={(e) => e.target.placeholder = placeHolder}
      />
    </React.Fragment>
  );
}
