import React from 'react';

export const SpymasterButton = (props) => {
  const {over, gameId, socket, name, spymaster} = props;

  if (over) {
    return null;
  }

  var classes = ['cluemaster-button'];
  if (spymaster) {
    classes.push('background-red');
  } else {
    classes.push('background-grey-very-dark');
  }

  const onButtonClicked = () => {
    socket.emit('add spymasters', gameId, name);
  }

  return (
    <React.Fragment>
      <button className={classes.join(' ')} onClick={onButtonClicked}>Cluemaster</button>
    </React.Fragment>
  );
}
