import React, {useEffect, useState} from 'react';
import {Page} from './Page.js';
import {Logo} from './Logo.js';
import cookie from 'react-cookies';

const App = (props) => {
  const {socket}=props;
  const [askTimer, setAskTimer] = useState(false);
  const [team, setTeam] = useState(null);
  const [name, setName] = useState('');

  const [gameId, setGameId] = useState(null);
  const [words, setWords] = useState([]);
  const [assassin, setAssassin] = useState('');
  const [turn, setTurn] = useState('blue');
  const [redWords, setRedWords] = useState([]);
  const [blueWords, setBlueWords] = useState([]);
  const [unguessedRed, setUnguessedRed] = useState([]);
  const [unguessedBlue, setUnguessedBlue] = useState([]);
  const [timerLength, setTimerLength] = useState(null);
  const [timerLapse, setTimerLapse] = useState(null);
  const [over, setOver] = useState(false);
  const [byStanderGuessed, setByStanderGuessed] = useState([]);
  const [blue, setBlue] = useState([]);
  const [red, setRed] = useState([]);
  const [spymasters, setSpymasters] = useState([]);

  useEffect(() => {
    socket.on('name and team selected', (playerName, playerTeam) => nameAndTeamSelected(playerName, playerTeam));
    socket.on('ask timer length', (gameFromServer) => askTimerLength(gameFromServer));
    socket.on('go to game', (gameFromServer) => goToGame(gameFromServer));
    socket.on('timer lapse set', (newExpiry, timerLength) => setGameTimer(newExpiry, timerLength));
    socket.on('start new game', (gameFromServer) => goToGame(gameFromServer));
    socket.on('switch team', (newTurn, newTimeLapse, redLeft, blueLeft, byStanderGuessed) => switchTeam(newTurn, newTimeLapse, redLeft, blueLeft, byStanderGuessed));
    socket.on('guessed correctly', (redLeft, blueLeft) => continueGuessing(redLeft, blueLeft));
    socket.on('user and game found', (user, gameFromServer) => matchUserWithGame(user, gameFromServer));
    socket.on('player switched team', (redTeam, blueTeam) => playerSwitchedTeam(redTeam, blueTeam));
    socket.on('assassin picked', () => assassinPicked());
    socket.on('win first', (redLeft, blueLeft) => gameOver(redLeft, blueLeft));

    socket.on('update spymasters', (newSpymasters) => updateSpecificGameProperties('spymasters', newSpymasters));
  });

  const nameAndTeamSelected = (playerName, playerTeam) => {
    updateSpecificGameProperties('name', playerName);
    updateSpecificGameProperties('team', playerTeam);
  }

  const assassinPicked = () => {
    setOver(true);
  };

  const gameOver = (redLeft, blueLeft) => {
    setOver(true);
    updateSpecificGameProperties('unguessedRed', redLeft);
    updateSpecificGameProperties('unguessedBlue', blueLeft);
  }

  const playerSwitchedTeam = (redTeam, blueTeam) => {
    updateSpecificGameProperties('red', redTeam);
    updateSpecificGameProperties('blue', blueTeam);
  }

  const updateSpecificGameProperties = (property, newValue) => {
    switch(property) {
      case 'name':
        setName(newValue);
        break;
      case 'team':
        setTeam(newValue);
        break;
      case 'spymasters':
        setSpymasters(newValue);
        break;
      case 'turn':
        setTurn(newValue);
        break;
      case 'timerLapse':
        setTimerLapse(newValue);
        break;
      case 'red':
        setRed(newValue);
        break;
      case 'blue':
        setBlue(newValue);
        break;
      case 'unguessedRed':
        setUnguessedRed(newValue);
        break;
      case 'unguessedBlue':
        setUnguessedBlue(newValue);
        break;
      case 'byStanderGuessed':
        setByStanderGuessed(newValue);
        break;
    }
  }

  const updateGameProperties = (game) => {
    setGameId(game.id);
    setWords(game.words);
    setAssassin(game.assassin);
    setTurn(game.turn);
    setRedWords(game.redWords);
    setBlueWords(game.blueWords);
    setUnguessedRed(game.unguessedRed);
    setUnguessedBlue(game.unguessedBlue);
    setTimerLength(game.timerLength);
    setTimerLapse(game.timerLapse);
    setOver(game.over);
    setByStanderGuessed(game.byStanderGuessed);
    setBlue(game.blue);
    setRed(game.red);
    setSpymasters(game.spymasters);
  }

  const setGameTimer = (newExpiry, timerLength) => {
    setTimerLength(timerLength);
    setTimerLapse(newExpiry);
    setAskTimer(false);
  }

  const matchUserWithGame = (user, gameFromServer) => {
    if (gameFromServer.blue.includes(user.name)) {
      setTeam('blue');
    } else if (gameFromServer.red.includes(user.name)) {
      setTeam('red');
    }
    updateGameProperties(gameFromServer);
    setName(user.name);
  }

  const continueGuessing = (redLeft, blueLeft) => {
    updateSpecificGameProperties('unguessedRed', redLeft);
    updateSpecificGameProperties('unguessedBlue', blueLeft);
  }

  const switchTeam = (newTurn, newTimeLapse, redLeft, blueLeft, byStanderGuessed) => {
    updateSpecificGameProperties('turn', newTurn);
    updateSpecificGameProperties('timerLapse', newTimeLapse);
    updateSpecificGameProperties('unguessedRed', redLeft);
    updateSpecificGameProperties('unguessedBlue', blueLeft);
    updateSpecificGameProperties('byStanderGuessed', byStanderGuessed);
  }

  const askTimerLength = (gameFromServer) => {
    updateGameProperties(gameFromServer);
    setAskTimer(true);
  }

  const goToGame = (gameFromServer) => {
    updateGameProperties(gameFromServer);
    setAskTimer(false);
  }

  if (name == '') {
    if (cookie.load('overCluedUser')) {
      const cookieId = cookie.load('overCluedUser');
      socket.emit('look for cookieId', cookieId);
    }
  }

  return (
    <div className="App">
      <Page
        socket={socket}
        gameId={gameId}
        timerLapse={timerLapse}
        timerLength={timerLength}
        askTimer={askTimer}
        team={team}
        name={name}
        setName={setName}
        over={over}
        redWords={redWords}
        blueWords={blueWords}
        unguessedRed={unguessedRed}
        unguessedBlue={unguessedBlue}
        byStanderGuessed={byStanderGuessed}
        assassin={assassin}
        turn={turn}
        words={words}
        red={red}
        blue={blue}
        spymasters={spymasters}
      />
    </div>
  );
}

export default App;
