import React from 'react';
import {PickTeam} from './PickTeam.js';
import {ShowTeams} from './ShowTeams.js';

export const Overlay = (props) => {
  const {socket, team, showTeamPicker, setShowTeamPicker, name, showTeam, setShowTeam, red, blue, gameId, spymasters} = props;

  var classes = ['overlay'];

  if (showTeamPicker || showTeam) {
    classes.push('expanded');
  }

  return (
    <div className={classes.join(" ")}>
      <PickTeam team={team} showTeamPicker={showTeamPicker} setShowTeamPicker={setShowTeamPicker} name={name} socket={socket} red={red} blue={blue} gameId={gameId} />
      <ShowTeams spymasters={spymasters} red={red} blue={blue} showTeam={showTeam} setShowTeam={setShowTeam} name={name} />
    </div>
  );
}
