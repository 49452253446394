import React from 'react';

export const ChangeTeamButton = (props) => {
  const {team, setShowTeamPicker} = props;

  if (!team) {
    return null;
  }

  var classes = ["change-team-button", "white", "underline"];

  const onButtonClicked = () => {
    setShowTeamPicker(true);
  }

  return (
    <React.Fragment>
      <button className={classes.join(' ')} onClick={onButtonClicked}>Change</button>
    </React.Fragment>
  );
}
