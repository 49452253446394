import React from 'react';

export const UnguessedIndicator = (props) => {
  const {unguessedRed, unguessedBlue} = props;

  return (
    <div className="score">
      Unguessed words: <span className="blue bold">{unguessedBlue.length}</span> - <span className="red bold">{unguessedRed.length}</span>
    </div>
  );
}
