import React from 'react';
import ReactDOM from 'react-dom';
import io from 'socket.io-client';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import App from './elements/App';

var socket = io();
if (process.env.NODE_ENV === 'development') {
  socket = io('http://localhost:8080');
}

ReactDOM.render(<App socket={socket} />, document.getElementById('root'));
serviceWorker.unregister();
