import React, {useState} from 'react';

export const TimerInput = (props) => {
  const {socket, gameId, askTimer} = props;
  const [timerSelected, setTimerSelected] = useState(180);

  if (!askTimer || !gameId) {
    return null;
  }

  const setGameTimerLength = (e) => {
    setTimerSelected(e.target.value);
  }

  const onSubmitTimer = () => {
    socket.emit('set timer length', timerSelected, gameId);
  }

  return (
    <div className="timer-input">
      Creating a Game with ID: <span className="bold underline">{gameId}</span>.
      <div className="time-selection">
        Set time limit per turn
        <select value={timerSelected} onChange={(e) => setGameTimerLength(e)}>
          <option value="60">1 minute</option>
          <option value="120">2 minutes</option>
          <option value="180">3 minutes</option>
          <option value="240">4 minutes</option>
          <option value="600">5 minutes</option>
        </select>
      </div>
      <button className="big-button background-red white animate__animated animate__fadeInUp" onClick={onSubmitTimer}>Play</button>
    </div>
  );
}
