import React, {useState} from 'react';
import {NameInput} from './NameInput.js';
import cookie from 'react-cookies';

export const PickTeam = (props) => {
  const {socket, team, showTeamPicker, setShowTeamPicker, name, blue, red, gameId} = props;
  const [selectedTeam, setSelectedTeam] = useState(team? team : 'blue');
  const [inputtedName, setInputtedName] = useState(name);

  if (!showTeamPicker) {
    return null;
  }

  const onTeamSelection = (e) => {
    setSelectedTeam(e.target.value);
  }

  const onSubmitTeam = () => {
    if (name != '' || isValidName(inputtedName)) {
      var userCookieId = null;
      if (cookie.load('overCluedUser')) {
        userCookieId = cookie.load('overCluedUser');
      } else {
        userCookieId = setUserCookie();
      }
      setShowTeamPicker(false);
      socket.emit('player chooses team', gameId, selectedTeam, inputtedName, userCookieId);
    }
  }

  const setUserCookie = () => {
    const expires = new Date();
    const maxAgeHours = 6;
    const maxAgeSecond = 60 * 60 * maxAgeHours;
    expires.setDate(Date.now() + 1000 * maxAgeSecond);
    const cookieId = getRandomString(25);
    cookie.save('overCluedUser', cookieId, { path: '/', expires, maxAge: maxAgeSecond});
    return cookieId;
  }

  const getRandomString = (length) => {
    var result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const onCancelTeamChange = () => {
    setShowTeamPicker(false);
  }

  const cancelButton = () => {
    if (team == null) {
      return null;
    }
    return (
      <button className="big-button background-grey black animate__fadeInUp animate__animated" onClick={onCancelTeamChange}>Cancel</button>
    );
  }

  const isValidName = (string) => {
    if (string.length < 1 || !isAlphaNumeric(string) || !isUniqueName(string)) {
      return false;
    }
    return true;
  }

  const isAlphaNumeric = (string) => {
    const letterNumber = /^[0-9a-zA-Z]+$/;
    if (string.match(letterNumber)) {
      return true;
    }
    return false;
  }

  const isUniqueName = (string) => {
    if (blue.includes(string) || red.includes(string)) {
      return false;
    }
    return true;
  }

  return (
    <div className="pick-team">
      <NameInput name={name} inputtedName={inputtedName} setInputtedName={setInputtedName} />
      <h2>Your Team</h2>
      <p>Prevent accidental selection when it's not your team's turn.</p>
      <div>
        <select className={selectedTeam} value={selectedTeam} onChange={(e) => onTeamSelection(e)}>
          <option className="blue" value="blue">Blue</option>
          <option className="red" value="red">Red</option>
        </select>
      </div>
      <button className="big-button background-blue white animate__fadeInUp animate__animated" onClick={onSubmitTeam}>Submit</button>
      {cancelButton()}
    </div>
  );
}
