import React from 'react';

export const Card = (props) => {
  const {socket, over, redWords, blueWords, unguessedRed, unguessedBlue, byStanderGuessed, assassin, turn, word, spymaster, team, gameId} = props;

  var classes = ['card'];

  if (over || spymaster) {
    classes.push('cluemaster');
    if (redWords.includes(word)) {
      classes.push('background-red');
      if (!unguessedRed.includes(word)) {
        classes.push('guessed');
      }
    } else if (blueWords.includes(word)) {
      classes.push('background-blue');
      if (!unguessedBlue.includes(word)) {
        classes.push('guessed');
      }
    } else if (byStanderGuessed.includes(word)) {
      classes.push('guessed');
    } else if (assassin == word) {
      classes.push('assassin');
    }
  } else {
    if (redWords.includes(word) && !unguessedRed.includes(word)) {
      classes.push('guessed');
      classes.push('background-red');
    } else if (blueWords.includes(word) && !unguessedBlue.includes(word)) {
      classes.push('guessed');
      classes.push('background-blue');
    } else if (byStanderGuessed.includes(word)) {
      classes.push('guessed');
    }
  }

  if (spymaster || classes.includes('guessed') || over || (turn != team)) {
    classes.push('disabled');
    return (
      <button disabled className={classes.join(' ')}>{word}</button>
    );
  }

  const onCardClicked = (e) => {
    socket.emit('card selected', gameId, word);
  }

  return (
    <React.Fragment>
      <button className={classes.join(' ')} onClick={onCardClicked}>{word}</button>
    </React.Fragment>
  );
}
