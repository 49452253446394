import React, {useState} from 'react';
import {Logo} from './Logo.js';

export const Instruction = (props) => {
  const {gameId, toggleExpand, expandInstruction} = props;

  if (gameId) {
    return null;
  }

  var classes = ['background-grey-very-dark', 'instruction'];
  var togglerLabel = "How to Play";
  if (expandInstruction) {
    classes.push('expanded');
    togglerLabel = "Close";
  }

  return (
    <div className={classes.join(' ')}>
      <div className="instruction-wrapper">
        <Logo />
        <h2>How to Play</h2>
        <p>All players need the same Game ID to play together.</p>
        <p>Divide players evenly into Red and Blue teams. Pick one Cluemaster for each team.</p>
        <p>The Cluemaster ( <img className="spymaster-player-icon" src="../images/spy.png" /> ) gives a clue about the words of that team's color. After the clue is given, the other players in that team tries to guess the words.</p>
        <p>A clue consists of <span className="bold">1 word</span> and <span className="bold">1 number</span>, e.g: "aquarium 2". The <span className="bold">number</span> reflects how many words on the board are related to the clue <span className="bold">word</span>. Words on the board cannot be used as a clue unless previously guessed. Zero or infinite is acceptable as <span className="bold">number</span>.</p>
        <p>A team can guess at most <span className="bold">number</span> + 1 words per turn.</p>
        <p>When a team guesses a word that's not their word, the turn is over.</p>
        <p>When the timer is up, the turn is over.</p>
        <p>If a team picks the exploding word, that team loses.</p>
        <button onClick={toggleExpand} className="instruction-button-close big-button white background-red">Close</button>
    </div>
    </div>
  );
}
