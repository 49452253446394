import React from 'react';

export const PlayerButton = (props) => {
  const {over, gameId, spymaster, socket, name} = props;

  if (over) {
    return null;
  }

  var classes = ['player-button'];
  if (!spymaster) {
    classes.push('background-red');
  } else {
    classes.push('background-grey-very-dark');
  }

  const onButtonClicked = () => {
    socket.emit('remove spymaster', gameId, name);
  }

  return (
    <React.Fragment>
      <button className={classes.join(' ')} onClick={onButtonClicked}>Player</button>
    </React.Fragment>
  );
}
