import React from 'react';
import {LoginPage} from './LoginPage.js';
import {GamePage} from './GamePage.js';

export const Page = (props) => {
  const {socket, askTimer, team, name, setName, gameId, timerLapse, timerLength, over, redWords, blueWords, unguessedRed, unguessedBlue, byStanderGuessed, assassin, turn, words, red, blue, spymasters} = props;

  if (!gameId || !timerLength) {
    return (
      <LoginPage gameId={gameId} socket={socket} askTimer={askTimer}/>
    );
  } else {
    return (
      <GamePage
        socket={socket}
        team={team}
        name={name}
        setName={setName}
        over={over}
        redWords={redWords}
        blueWords={blueWords}
        unguessedRed={unguessedRed}
        unguessedBlue={unguessedBlue}
        byStanderGuessed={byStanderGuessed}
        assassin={assassin}
        turn={turn}
        words={words}
        timerLength={timerLength}
        timerLapse={timerLapse}
        red={red}
        blue={blue}
        spymasters={spymasters}
        gameId={gameId}
      />
    );
  }
}
