import React, {useState} from 'react';
import {TimerInput} from './TimerInput.js';
import {GameIdInput} from './GameIdInput.js';
import {Instruction} from './Instruction.js';
import {Logo} from './Logo.js';
import {InstructionButton} from './InstructionButton.js';

export const LoginPage = (props) => {
  const {socket, askTimer, gameId} = props;
  const [expandInstruction, setExpandInstruction] = useState(false);

  const toggleExpand = () => {
    setExpandInstruction(!expandInstruction);
  }

  var classes = ["login-page", "page", "background-grey-very-dark"];
  if (expandInstruction) {
    classes.push('instruction-expanded');
  }

  return (
    <div className={classes.join(" ")}>
      <Logo linkToHome={false} pageDisplay="login"/>
      <GameIdInput socket={socket} gameId={gameId} />
      <InstructionButton toggleExpand={toggleExpand} gameId={gameId} />
      <Instruction gameId={gameId} toggleExpand={toggleExpand} expandInstruction={expandInstruction} />
      <TimerInput gameId={gameId} askTimer={askTimer} socket={socket} />
    </div>
  );
}
