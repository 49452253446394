import React from 'react';

export const NextGameButton = (props) => {
  const {socket, over, gameId} = props;

  const onNextGameButtonClicked = (e) => {
    var startNewGame = false;
    if (over) {
      startNewGame = true;
    } else {
      if (window.confirm("Are you sure you want to start a new game?")) {
        startNewGame = true;
      }
    }
    if (startNewGame) {
      socket.emit('want new game', gameId);
    }
  }

  return (
    <React.Fragment>
      <button className="big-button background-blue white responsive-button" onClick={onNextGameButtonClicked}>Next Game</button>
    </React.Fragment>
  );
}
