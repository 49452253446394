import React from 'react';
import {Card} from './Card.js';

export const Board = (props) => {
  const {socket, game, over, redWords, blueWords, unguessedRed, unguessedBlue, byStanderGuessed, assassin, turn, team, spymaster, words, gameId} = props;

  return (
    <div className="board">
      {words.map((word) =>
        <Card word={word} game={game} over={over} redWords={redWords} blueWords={blueWords} unguessedRed={unguessedRed} unguessedBlue={unguessedBlue} byStanderGuessed={byStanderGuessed} assassin={assassin} turn={turn} key={word} socket={socket} spymaster={spymaster} team={team} gameId={gameId} />
      )}
    </div>
  );
}
