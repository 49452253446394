import React from 'react';

export const TurnIndicator = (props) => {
  const {turn, over, unguessedRed, unguessedBlue} = props;
  var classes = ['turn-indicator'];
  var label = "It's " + turn + "'s turn";
  if (over) {
    classes.push('game-over');
    classes.push('background-white');
    if (unguessedBlue == 0) {
      label = (<span className="blue won">🏆 Blue team won! 🎉</span>);
    } else if (unguessedRed == 0) {
      label = (<span className="red won">🏆 Red team won! 🎉</span>);
    } else {
      if (turn == 'blue') {
        label = (<span className="red won">🏆 Red team won! 🎉</span>);
      } else {
        label = (<span className="blue won">🏆 Blue team won! 🎉</span>);
      }
    }
  }

  return (
    <div className={classes.join(" ")}>{label}</div>
  );
}
