import React, {useState} from 'react';

export const GameIdInput = (props) => {
  const {socket, gameId} = props;
  const [proposedGameId, setProposedGameId] = useState('');
  const [human, setHuman] = useState(true);

  const placeHolder = "Enter Game ID";

  const onGameIdSubmit = (e) => {
    e.preventDefault();
    if (human) {
      if (isValidId(proposedGameId)) {
        socket.emit('join game', proposedGameId.toLowerCase());
      }
    }
  }

  if (gameId) {
    return null;
  }

  const isValidId = (string) => {
    if (string.length < 4 || !isAlphaNumeric(string)) {
      return false;
    }
    return true;
  }

  const onCheckboxChange = (e) => {
    const newValue = e.target.checked;
    setHuman(!newValue);
  }

  const isAlphaNumeric = (string) => {
    const letterNumber = /^[0-9a-zA-Z]+$/;
    if (string.match(letterNumber)) {
      return true;
    }
    return false;
  }

  return (
    <React.Fragment>
      <form className="game-id-input" onSubmit={onGameIdSubmit}>
        <input
          type="text"
          name="gameId"
          placeholder={placeHolder}
          onFocus={(e) => e.target.placeholder=""}
          onBlur={(e) => e.target.placeholder = placeHolder}
          onChange={e => setProposedGameId(e.target.value)}/>
        <input type="checkbox" name="human" onChange={onCheckboxChange} value={!human} />
        <input className="background-grey-very-dark white" type="submit" value=">" />
      </form>
      <div className="login-help">Game ID is case insensitive, should be alphanumeric and at least 4 characters.</div>
    </React.Fragment>
  );
}
