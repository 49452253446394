import React, {useState, useEffect} from 'react';

export const Timer = (props) => {
  const {socket, startingTimer, secondToTimeLeftString, over, timerLapse, gameId} = props;
  const [timeRemaining, setTimeRemaining] = useState(startingTimer);

  useEffect(() => {
    setTimeout(() => {
      if (!over) {
        setTimeRemaining(populateTimeLeft());
      }
    }, 1000);
  });

  if (over || !timeRemaining) {
    return null;
  }

  const populateTimeLeft = () => {
    const secondsRemaining = (timerLapse - Date.now()) / 1000;
    if (timerLapse != null && secondsRemaining < 0) {
      socket.emit('end turn reset timer', gameId, timerLapse);
      return startingTimer;
    }
    return secondToTimeLeftString(secondsRemaining);
  }

  return (
    <div className="timer">{timeRemaining}</div>
  );
}
