import React, {useState} from 'react';
import {Timer} from './Timer.js';
import {TurnIndicator} from './TurnIndicator.js';
import {Board} from './Board.js';
import {EndTurnButton} from './EndTurnButton.js';
import {UnguessedIndicator} from './UnguessedIndicator.js';
import {PlayerButton} from './PlayerButton.js';
import {SpymasterButton} from './SpymasterButton.js';
import {NextGameButton} from './NextGameButton.js';
import {Overlay} from './Overlay.js';
import {TeamInfo} from './TeamInfo.js';
import {Logo} from './Logo.js';

export const GamePage = (props) => {
  const {socket, over, gameId, redWords, blueWords, unguessedRed, unguessedBlue, byStanderGuessed, assassin, turn, team, name, words, timerLapse, timerLength, red, blue, spymasters} = props;
  const [showTeamPicker, setShowTeamPicker] = useState(team == null);
  const [showTeam, setShowTeam] = useState(false);

  const secondToTimeLeftString = (seconds) => {
    const timeLeft = {
      'minutes': niceMinuteSecond(Math.floor((seconds / 60) % 60)),
      'seconds': niceMinuteSecond(Math.floor((seconds % 60)))
    };
    return timeLeft.minutes + ":" + timeLeft.seconds;
  }

  const spymaster = spymasters.includes(name);

  const getStartingTimer= () => {
    switch (timerLength) {
        case 60:
          return "01:00";
        case 120:
          return "02:00";
        case 180:
          return "03:00";
        case 240:
          return "04:00";
        case 300:
          return "05:00";
        default:
          return "03:00";
    }
  }

  const niceMinuteSecond = (number) => {
    if (number < 10) {
      return "0" + number.toString();
    }
    return number.toString();
  }

  return (
    <React.Fragment>
      <Logo linkToHome={true} backgroundColor="background-grey-very-dark"/>
      <div className="game-page page">
        <Overlay team={team} showTeamPicker={showTeamPicker} setShowTeamPicker={setShowTeamPicker} name={name} showTeam={showTeam} setShowTeam={setShowTeam} socket={socket} red={red} blue={blue} gameId={gameId} spymasters={spymasters} />
        Game ID: <span className="bold game-id">{gameId}</span>
        <TeamInfo team={team} setShowTeamPicker={setShowTeamPicker} setShowTeam={setShowTeam} name={name} />
        <div className="game-page-top">
          <div className="game-page-left">
            <TurnIndicator turn={turn} over={over} unguessedRed={unguessedRed} unguessedBlue={unguessedBlue} />
            <EndTurnButton gameId={gameId} socket={socket} over={over} turn={turn} />
          </div>
          <div className="game-page-right">
            <UnguessedIndicator unguessedBlue={unguessedBlue} unguessedRed={unguessedRed} />
            <div className="timer-area">
              <Timer socket={socket} startingTimer={getStartingTimer} secondToTimeLeftString={secondToTimeLeftString} timerLapse={timerLapse} over={over} gameId={gameId} turn={turn} />
            </div>
          </div>
        </div>
        <Board over={over} redWords={redWords} blueWords={blueWords} unguessedRed={unguessedRed} unguessedBlue={unguessedBlue} byStanderGuessed={byStanderGuessed} assassin={assassin} turn={turn} socket={socket} team={team} name={name} spymaster={spymaster} words={words} gameId={gameId} />
        <div className="game-page-bottom">
          <div className="game-page-left">
            <PlayerButton over={over} gameId={gameId} name={name} socket={socket} spymaster={spymaster} />
            <SpymasterButton socket={socket} name={name} spymaster={spymaster} gameId={gameId} over={over} />
          </div>
          <NextGameButton gameId={gameId} socket={socket} over={over} />
        </div>
      </div>
    </React.Fragment>
  );
}
