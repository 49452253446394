import React from 'react';

export const ShowTeams = (props) => {
  const {spymasters, red, blue, showTeam, setShowTeam, name} = props;

  if (!showTeam) {
    return null;
  }

  const onButtonClicked = () => {
    setShowTeam(false);
  }

  const listTeamMembers = (color) => {
    var members = [];
    var team = blue;
    if (color == 'red') {
      team = red;
    }

    team = rearrangeTeamSpymasterFirst(team);
    team.forEach(element =>
      members.push(listItem(element))
    );
    return members;
  }

  const rearrangeTeamSpymasterFirst = (teamArray) => {
    var members = [];
    for (const member of teamArray) {
      if (spymasters.includes(member)) {
        members.push(member);
      }
    }
    for (const member of teamArray) {
      if (!members.includes(member)) {
        members.push(member);
      }
    }
    return members;
  }

  const listItem = (playerName) => {
    var printedName = playerName;
    if (playerName == name) {
      printedName += " (you)";
    }
    if (spymasters.includes(playerName)) {
      return (
        <li key={playerName}><img className="spymaster-player-icon" src="../images/spy.png" />{printedName}</li>
      );
    } else {
      return (
        <li key={playerName}>{printedName}</li>
      );
    }
  }

  return (
    <div className="show-teams">
      <h2>Teams</h2>
      <div className="teams">
        <div className="team-wrapper">
          <h3 className="red">Red</h3>
          <ul>{listTeamMembers('red')}</ul>
        </div>
        <div className="team-wrapper">
          <h3 className="blue">Blue</h3>
          <ul>{listTeamMembers('blue')}</ul>
      </div>
      </div>
      <button className="big-button background-blue white animate__fadeInUp animate__animated" onClick={onButtonClicked}>Close</button>
    </div>
  );
}
