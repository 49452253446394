import React from 'react';

export const Logo = (props) => {
  const {linkToHome, backgroundColor, pageDisplay} = props;

  const classes = ['logo', backgroundColor];

  if (pageDisplay == 'login') {
    classes.push('animate__animated');
    classes.push('animate__fadeInDown');
  }

  if (linkToHome) {
    return (
      <div className={classes.join(' ')}>
        <a href="/"><span className="red bold">OVER</span>
        <span className="blue bold">CLUED</span></a>
      </div>
    );
  } else {
    return (
      <div className={classes.join(' ')}>
        <span className="red bold">OVER</span>
        <span className="blue bold">CLUED</span>
      </div>
    );
  }
}
