import React from 'react';

export const EndTurnButton = (props) => {
  const {socket, over, gameId, turn} = props;

  if (over) {
    return null;
  }

  var classes = ['big-button', 'responsive-button', 'white'];
  classes.push("background-" + turn);

  const onEndTurnButtonClicked = (e) => {
    socket.emit('manual end turn', gameId);
  }

  return (
    <React.Fragment>
      <button className={classes.join(' ')} onClick={onEndTurnButtonClicked}>End Turn</button>
    </React.Fragment>
  );
}
