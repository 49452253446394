import React from 'react';

export const InstructionButton = (props) => {
  const {toggleExpand, gameId} = props;

  if (gameId) {
    return null;
  }

  return (
    <button onClick={toggleExpand} className="instruction-button-open big-button white background-red">How To Play</button>
  );
}
